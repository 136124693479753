import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Header } from 'tabler-react';
import Document from './Blocks/Document';
import './Identity.scss';
import ApiError from '../../../../Common/ApiError';
import { capitalize } from '../../../../../helpers';
import { createApiClient } from '../../../../../services/api-client';
import Actions from 'src/components/Content/Compliance/Users/Identity/Blocks/Actions';

const ONDATO_STATUS_COLOR = {
    confirmed: 'success',
    processing: 'primary',
    created: 'primary',
    expires_soon: 'primary',
    expired: 'danger',
    failed: 'danger',
};

const ONDATO_STATUS_ICON = {
    confirmed: 'check',
    processing: 'clock',
    created: 'clock',
    expires_soon: 'clock',
    expired: 'x',
    failed: 'x',
};

const Identity = ({ user, updateUser, updateUserState }) => {
    // const [napierLoading, setNapierLoading] = useState(false);
    // const [napierError, setNapierError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [identityVerificationStatus, setIdentityVerificationStatus] = useState('');
    const [error, setError] = useState(null);
    const [errorIdentityVerificationNotFound, setErrorIdentityVerificationNotFound] = useState(null);

    // const [scoreCardData, setScoreCardData] = useState({
    //     status: null,
    //     created_at: null,
    //     user_id: null,
    //     scorecard_id: null,
    //     task: null,
    //     risk_score: null,
    //     risk_level: null,
    // });

    const scoreCardData = {
        status: null,
        created_at: null,
        user_id: null,
        scorecard_id: null,
        task: null,
        risk_score: null,
        risk_level: null,
    };

    const resolveOndatoStatusColor = (status) => {
        if (ONDATO_STATUS_COLOR[status]) {
            return ONDATO_STATUS_COLOR[status];
        }

        return 'secondary';
    };

    const resolveOndatoStatusIcon = (status) => {
        if (ONDATO_STATUS_ICON[status]) {
            return ONDATO_STATUS_ICON[status];
        }

        return 'x';
    };

    const api = useMemo(() => createApiClient(), []);

    // const fetchScoreCard = useCallback(async () => {
    //     setNapierLoading(true);
    //
    //     try {
    //         const { data } = await api.get(`admin-napier-integration/users/${user.id}/scorecard`);
    //
    //         setScoreCardData(data);
    //     } catch (error) {
    //         if (error.data.code === 'scorecard_not_found') {
    //             setNapierError(error.data.message);
    //
    //             return;
    //         }
    //
    //         toast.error(`Failed to get scorecard data: ${error.data.message}`);
    //     } finally {
    //         setNapierLoading(false);
    //     }
    // }, [api, user.id]);

    const fetchUserIdentityVerificationStatus = useCallback(async () => {
        setLoading(true);

        try {
            const { data } = await api.get(`/admin-user-identity-verification/users/${user.id}/user-identity-verification`);

            setIdentityVerificationStatus(data.status);
        } catch (error) {
            if (error.data.code === 'user_identity_verification_not_found') {
                setErrorIdentityVerificationNotFound(error.data);

                return;
            }

            setError(error.data);
        } finally {
            setLoading(false);
        }
    }, [api, user.id]);

    useEffect(() => {
        // fetchScoreCard();
        fetchUserIdentityVerificationStatus();
    }, [fetchUserIdentityVerificationStatus]);

    return (
        <>
            <ApiError error={error} />
            <Header.H3>
                Identity
                {errorIdentityVerificationNotFound === null && (
                    <Button
                        color={resolveOndatoStatusColor(identityVerificationStatus)}
                        icon={resolveOndatoStatusIcon(identityVerificationStatus)}
                        disabled={true}
                        size='sm'
                        className='ml-3 defaultCursor'
                        loading={isLoading}
                    >
                        {identityVerificationStatus === 'expires_soon' ? 'Expires soon' : capitalize(
                            identityVerificationStatus)}
                    </Button>
                )}
            </Header.H3>
            <Grid.Row>
                <Grid.Col>
                    <Actions
                        user={user}
                        updateUser={updateUser}
                        updateUserState={updateUserState}
                        napierData={scoreCardData}
                    />
                    {/*<Napier*/}
                    {/*    userId={user.id}*/}
                    {/*    userHash={user.hash}*/}
                    {/*    data={scoreCardData}*/}
                    {/*    setData={setScoreCardData}*/}
                    {/*    error={napierError}*/}
                    {/*    loading={napierLoading}*/}
                    {/*/>*/}
                </Grid.Col>
                <Grid.Col>
                    <Document userId={user.id} userType={user.type}/>
                </Grid.Col>
            </Grid.Row>
        </>
    );
};

Identity.propTypes = {
    user: PropTypes.object.isRequired,
    updateUser: PropTypes.func.isRequired,
    updateUserState: PropTypes.func.isRequired,
};

export default Identity;
