import { requireAuthentication } from '../hoc/authentication';
import Transactions from '../components/Content/Transactions';
import Transaction from '../components/Content/Transactions/Transaction';
import CompanyTypes from '../components/Content/CompanyTypes';
import CompanyTypesCreateOrEdit from '../components/Content/CompanyTypes/CompanyTypesCreateOrEdit';
import MonthlyFees from '../components/Content/Fees/MonthlyFees';
import MonthlyFeesCreate from '../components/Content/Fees/MonthlyFees/MonthlyFeesCreate';
import FeeWallets from '../components/Content/Configuration/FeeWallets';
import FeeWalletsCreate from '../components/Content/Configuration/FeeWallets/FeeWalletsCreate';
import SatchelPayMasterAccount from '../components/Content/Configuration/SatchelPayMasterAccount';
import TransactionFees from '../components/Content/Fees/TransactionFees';
import TransactionFeeCreate from '../components/Content/Fees/TransactionFees/TransactionFeeCreate';
import TransactionFeeEdit from '../components/Content/Fees/TransactionFees/TransactionFeeEdit';
import PricingGroups from '../components/Content/Fees/PricingGroups';
import PricingGroupCreate from '../components/Content/Fees/PricingGroups/PricingGroupCreate';
import PricingGroupEdit from '../components/Content/Fees/PricingGroups/PricingGroupEdit';
import Reporting from '../components/Content/Reporting';
import ComplianceUsers from '../components/Content/Compliance/Users';
import ComplianceUser from '../components/Content/Compliance/Users/User';
import ComplianceActionsList from '../components/Content/Compliance/ActionsList';
import AdminUsers from '../components/Content/Configuration/AdminUsers';
import AdminUser from '../components/Content/Configuration/AdminUsers/AdminUser';
import ComplianceDocumentTypes from '../components/Content/Compliance/DocumentTypes';
import ComplianceDocumentTypesCreate from '../components/Content/Compliance/DocumentTypes/DocumentTypesCreate';
import ComplianceDocumentTypesEdit from '../components/Content/Compliance/DocumentTypes/DocumentTypesEdit';
import NotFoundPage from '../components/NotFoundPage';
import CreateAdminUser from '../components/Content/Configuration/AdminUsers/CreateAdminUser';
import EditAdminUser from '../components/Content/Configuration/AdminUsers/AdminUser/Actions/EditAdminUser';
import PermissionGroups from '../components/Content/Configuration/PermissionGroups/PermissionGroups';
import DefaultWalletLimits from '../components/Content/Configuration/DefaultWalletLimits';
import Wallets from '../components/Content/Users';
import Dashboard from '../components/Content/Dashboard';
import User from '../components/Content/Users/User';
import OndatoTest from '../components/Content/Compliance/OndatoTest';
import BankHolidays from '../components/Content/Configuration/BankHolidays';
import BankHolidaysCreate from '../components/Content/Configuration/BankHolidays/BankHolidaysCreate';
import MonthlyFeesEdit from '../components/Content/Fees/MonthlyFees/MonthlyFeesEdit';
import TermsAndConditionsTypes from '../components/Content/Compliance/TermsAndConditions';
import TermsAndConditionsCreateOrEdit from '../components/Content/Compliance/TermsAndConditions/TermsAndConditionsCreateOrEdit';
import FXMarkupFees from '../components/Content/Fees/FXMarkupFees';
import BankAccounts from 'src/components/Content/Bank/BankAccounts';
import AccountStatement from 'src/components/Content/Bank/AccountStatement';
import BookingEntries from 'src/components/Content/Bookkeeping/BookingEntries';
import IndustraStatement from 'src/components/Content/Configuration/IndustraStatement';
import Payments from 'src/components/Content/Payments/Payments';
import SettlementReports from 'src/components/Content/Payments/SettlementReports';

const ProtectedDashboardPage = requireAuthentication(Dashboard);
const ProtectedWalletsPage = requireAuthentication(Wallets);
const ProtectedUserPage = requireAuthentication(User);

const ProtectedTransactionsPage = requireAuthentication(Transactions);
const ProtectedTransactionPage = requireAuthentication(Transaction);
const ProtectedCompanyTypesPage = requireAuthentication(CompanyTypes);
const ProtectedCompanyTypesCreateOrEditPage = requireAuthentication(CompanyTypesCreateOrEdit);

const ProtectedMonthlyFeesPage = requireAuthentication(MonthlyFees);
const ProtectedMonthlyFeesCreatePage = requireAuthentication(MonthlyFeesCreate);
const ProtectedMonthlyFeesEditPage = requireAuthentication(MonthlyFeesEdit);

const ProtectedFXMarkupFeesPage = requireAuthentication(FXMarkupFees);

const ProtectedConfigurationFeeWalletsPage = requireAuthentication(FeeWallets);
const ProtectedConfigurationFeeWalletsCreatePage = requireAuthentication(FeeWalletsCreate);

const ProtectedConfigurationSatchelPayMasterAccountPage = requireAuthentication(SatchelPayMasterAccount);

const ProtectedTransactionFeesPage = requireAuthentication(TransactionFees);
const ProtectedTransactionCreateFeePage = requireAuthentication(TransactionFeeCreate);
const ProtectedTransactionEditFeePage = requireAuthentication(TransactionFeeEdit);

const ProtectedPricingGroupsPage = requireAuthentication(PricingGroups);
const ProtectedPricingGroupCreatePage = requireAuthentication(PricingGroupCreate);
const ProtectedPricingGroupEditPage = requireAuthentication(PricingGroupEdit);

const ProtectedReportingPage = requireAuthentication(Reporting);

const ProtectedComplianceUsersPage = requireAuthentication(ComplianceUsers);
const ProtectedComplianceUserPage = requireAuthentication(ComplianceUser);

const ProtectedComplianceActionsList = requireAuthentication(ComplianceActionsList);

const ProtectedAdminUsersPage = requireAuthentication(AdminUsers);
const ProtectedAdminUserPage = requireAuthentication(AdminUser);

const ProtectedCreateBankHolidaysPage = requireAuthentication(BankHolidaysCreate);
const ProtectedBankHolidaysPage = requireAuthentication(BankHolidays);

const ProtectedComplianceDocumentTypesPage = requireAuthentication(ComplianceDocumentTypes);
const ProtectedComplianceDocumentTypesCreatePage = requireAuthentication(ComplianceDocumentTypesCreate);
const ProtectedComplianceDocumentTypesEditPage = requireAuthentication(ComplianceDocumentTypesEdit);
const ProtectedOndatoTestPage = requireAuthentication(OndatoTest);
const ProtectedTermsAndConditions = requireAuthentication(TermsAndConditionsTypes);
const ProtectedTermsAndConditionsCreateOrEdit = requireAuthentication(TermsAndConditionsCreateOrEdit);

const ProtectedCreateAdminUserPage = requireAuthentication(CreateAdminUser);
const ProtectedEditAdminUserPage = requireAuthentication(EditAdminUser);
const ProtectedPermissionGroupsPage = requireAuthentication(PermissionGroups);

const ProtectedDefaultWalletLimitsPage = requireAuthentication(DefaultWalletLimits);

// const ProtectedCentrolinkPage = requireAuthentication(Centrolink);
// const ProtectedCentrolinkMessageListPage = requireAuthentication(CentrolinkMessageList);
// const ProtectedCentrolinkParticipantsPage = requireAuthentication(Participants);

const ProtectedBankAccountsPage = requireAuthentication(BankAccounts);
const ProtectedBankAccountStatementPage = requireAuthentication(AccountStatement);

const ProtectedBookkeepingBookingEntries = requireAuthentication(BookingEntries);

const ProtectedIndustraStatementPage = requireAuthentication(IndustraStatement);

const ProtectedPaymentsPage = requireAuthentication(Payments);
const ProtectedSettlementReportsPage = requireAuthentication(SettlementReports);

export const routes = [
    {
        path: '/dashboard',
        exact: true,
        component: ProtectedDashboardPage,
    },
    {
        path: '/users',
        exact: true,
        component: ProtectedWalletsPage,
        permission: 'wallets',
    },
    {
        path: '/users/:id',
        exact: false,
        component: ProtectedUserPage,
        permission: 'wallets',
    },

    {
        path: '/transactions',
        exact: true,
        component: ProtectedTransactionsPage,
        permission: 'transactions',
    },
    {
        path: '/transactions/:id',
        exact: false,
        component: ProtectedTransactionPage,
        permission: 'transactions',
    },

    {
        path: '/company-types',
        exact: true,
        component: ProtectedCompanyTypesPage,
        permission: 'company_types',
    },
    {
        path: '/company-types/create',
        exact: true,
        component: ProtectedCompanyTypesCreateOrEditPage,
        permission: 'company_types',
    },
    {
        path: '/company-types/edit/:id',
        exact: false,
        component: ProtectedCompanyTypesCreateOrEditPage,
        permission: 'company_types',
    },

    {
        path: '/fee/monthly-fees',
        exact: true,
        component: ProtectedMonthlyFeesPage,
        permission: 'fees',
    },
    {
        path: '/fee/monthly-fees/create',
        exact: true,
        component: ProtectedMonthlyFeesCreatePage,
        permission: 'fees',
    },
    {
        path: '/fee/monthly-fees/edit/:id',
        exact: true,
        component: ProtectedMonthlyFeesEditPage,
        permission: 'fees',
    },

    {
        path: '/fee/fx-markup-fees',
        exact: true,
        component: ProtectedFXMarkupFeesPage,
        permission: 'fees',
    },

    {
        path: '/fee/transaction-fees',
        exact: true,
        component: ProtectedTransactionFeesPage,
        permission: 'fees',
    },
    {
        path: '/fee/transaction-fees/create',
        exact: true,
        component: ProtectedTransactionCreateFeePage,
        permission: 'fees',
    },
    {
        path: '/fee/transaction-fees/edit/:id',
        exact: false,
        component: ProtectedTransactionEditFeePage,
        permission: 'fees',
    },

    {
        path: '/fee/pricing-groups',
        exact: true,
        component: ProtectedPricingGroupsPage,
        permission: 'fees',
    },
    {
        path: '/fee/pricing-groups/create',
        exact: true,
        component: ProtectedPricingGroupCreatePage,
        permission: 'fees',
    },
    {
        path: '/fee/pricing-groups/edit/:id',
        exact: false,
        component: ProtectedPricingGroupEditPage,
        permission: 'fees',
    },

    {
        path: '/configuration/fee-wallets',
        exact: true,
        component: ProtectedConfigurationFeeWalletsPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/fee-wallets/create',
        exact: true,
        component: ProtectedConfigurationFeeWalletsCreatePage,
        permission: 'configurations',
    },

    {
        path: '/configuration/satchelpay',
        exact: true,
        component: ProtectedConfigurationSatchelPayMasterAccountPage,
        permission: 'configurations',
    },

    {
        path: '/configuration/admin-users',
        exact: true,
        component: ProtectedAdminUsersPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/admin-users/create',
        exact: true,
        component: ProtectedCreateAdminUserPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/admin-users/:id',
        exact: true,
        component: ProtectedAdminUserPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/admin-users/edit/:id',
        exact: false,
        component: ProtectedEditAdminUserPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/bank-holidays',
        exact: true,
        component: ProtectedBankHolidaysPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/bank-holidays/create',
        exact: true,
        component: ProtectedCreateBankHolidaysPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/roles-and-permissions',
        exact: true,
        component: ProtectedPermissionGroupsPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/ondato',
        exact: true,
        component: ProtectedPermissionGroupsPage,
        permission: 'configurations',
    },
    {
        path: '/configuration/default-wallet-limits',
        exact: true,
        component: ProtectedDefaultWalletLimitsPage,
        permission: 'configurations',
    },

    {
        path: '/reporting/bank-of-lithuania',
        exact: true,
        component: ProtectedReportingPage,
        permission: 'reporting',
    },

    {
        path: '/compliance/users/',
        exact: true,
        component: ProtectedComplianceUsersPage,
        permission: 'compliance',
    },
    {
        path: '/compliance/users/:id/',
        exact: true,
        component: ProtectedComplianceUserPage,
        permission: 'compliance',
    },
    {
        path: '/compliance/actions-list/pending-transactions',
        exact: true,
        component: ProtectedComplianceActionsList,
        permission: 'compliance',
    },
    {
        path: '/compliance/actions-list/pending-transactions',
        exact: true,
        component: ProtectedComplianceActionsList,
        permission: 'compliance',
    },
    {
        path: '/compliance/actions-list/pending-verification',
        exact: true,
        component: ProtectedComplianceActionsList,
        permission: 'compliance',
    },
    {
        path: '/compliance/actions-list/transaction-documentation',
        exact: true,
        component: ProtectedComplianceActionsList,
        permission: 'compliance',
    },
    {
        path: '/compliance/document-types',
        exact: true,
        component: ProtectedComplianceDocumentTypesPage,
        permission: 'compliance',
    },
    {
        path: '/compliance/document-types/create',
        exact: true,
        component: ProtectedComplianceDocumentTypesCreatePage,
        permission: 'compliance',
    },
    {
        path: '/compliance/document-types/edit/:id',
        exact: false,
        component: ProtectedComplianceDocumentTypesEditPage,
        permission: 'compliance',
    },
    {
        path: '/compliance/ondato-test',
        exact: false,
        component: ProtectedOndatoTestPage,
        permission: 'compliance',
    },
    {
        path: '/compliance/terms-conditions',
        exact: true,
        component: ProtectedTermsAndConditions,
        permission: 'compliance',
    },
    {
        path: '/compliance/terms-conditions/create',
        exact: true,
        component: ProtectedTermsAndConditionsCreateOrEdit,
        permission: 'compliance',
    },
    {
        path: '/compliance/terms-conditions/edit/:id',
        exact: false,
        component: ProtectedTermsAndConditionsCreateOrEdit,
        permission: 'compliance',
    },
    // {
    //     path: '/centrolink/transaction-files',
    //     exact: true,
    //     component: ProtectedCentrolinkPage,
    //     permission: 'transactions',
    // },
    // {
    //     path: '/centrolink/:id/:type',
    //     exact: false,
    //     component: ProtectedCentrolinkMessageListPage,
    //     permission: 'transactions',
    // },
    // {
    //     path: '/centrolink/participants',
    //     exact: false,
    //     component: ProtectedCentrolinkParticipantsPage,
    //     permission: 'transactions',
    // },
    {
        path: '/bank/bank-accounts',
        exact: true,
        component: ProtectedBankAccountsPage,
        permission: 'transactions',
    },
    {
        path: '/banks/:bank_key/bank-accounts/:id/statement',
        exact: false,
        component: ProtectedBankAccountStatementPage,
        permission: 'transactions',
    },
    {
        path: '/bookkeeping/booking-entries',
        exact: true,
        component: ProtectedBookkeepingBookingEntries,
        permission: 'bookkeeping',
    },
    {
        path: '/configuration/industra-statement',
        exact: true,
        component: ProtectedIndustraStatementPage,
    },
    {
        path: '/payment/payments',
        exact: true,
        component: ProtectedPaymentsPage,
    },
    {
        path: '/payment/settlement-reports',
        exact: true,
        component: ProtectedSettlementReportsPage,
    },
    {
        path: '*',
        exact: false,
        component: NotFoundPage,
    },
];
