import { Link, withRouter } from 'react-router-dom';
import SiteWrapper from 'src/components/SiteWrapper';
import PaginatedFilteredList from 'src/components/Common/PaginatedFilteredList';
import React, { useCallback, useMemo, useState } from 'react';
import {
    formatAmountAndCurrency,
    objectToOptions,
    statementTransactionRelation,
} from 'src/helpers';
import { FILTER_MASKS } from 'src/constants';
import { Button } from 'tabler-react';
import CreateTransactionModal from 'src/components/Content/Bank/CreateTransactionModal';
import { createApiClient as api } from 'src/services/api-client';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import RefundTransactionModal from 'src/components/Content/Bank/RefundTransactionModal';
import UploadStatementModal from 'src/components/Content/Bank/UploadStatementModal';

const BANK_ACCOUNT_TRANSACTION_TYPE_CREDIT = 'credit';

const AccountStatement = ({ match }) => {
    const [items, setItems] = useState([]);
    const [isCreateTransactionModalOpen, setCreateTransactionModalOpen] = useState(false);
    const [isRefundTransactionModalOpen, setRefundTransactionModalOpen] = useState(false);
    const [activeBankAccountTransaction, setActiveBankAccountTransaction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);

    const {
        params: { id, bank_key },
    } = useMemo(() => match, [match]);

    const updateItems = (items) => {
        setItems(items);
    };

    const updateItem = useCallback(
        (item) => {
            const filteredItems = items.map((transaction) => (item.id === transaction.id ? item : transaction));

            setItems(filteredItems);
        },
        [items],
    );

    const bankAccountsURI = `/admin-bank-account/bank-accounts/${id}/bank-account-transactions/paginated`;

    const columns = useMemo(
        () => [
            {
                Header: 'Creation Date',
                accessor: 'creation_date',
                sortable: false,
                Cell: (row) => (<span>{row.original.date}</span>),
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortable: false,
                Cell: (row) => (<span>{row.original.type}</span>),
            },
            {
                Header: 'Payment Id',
                accessor: 'payment_id',
                sortable: false,
                Cell: (row) => (<span>{row.original.payment_id}</span>),
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortable: false,
                Cell: (row) => renderStatementTransactionStatus(row),
            },
            {
                Header: 'Amount',
                id: 'amount',
                Cell: (row) => formatAmountAndCurrency(
                    row.original.amount,
                    row.original.currency
                ),
            },
            {
                Header: 'Sender Name',
                accessor: 'sender_name',
                sortable: false,
                Cell: (row) => (<span>{row.original.sender_name}</span>),
            },
            {
                Header: 'Sender Account',
                accessor: 'sender_account',
                sortable: false,
                Cell: (row) => (<span>{row.original.sender_account}</span>),
            },
            {
                Header: 'Details',
                accessor: 'details',
                sortable: false,
                Cell: (row) => (<span>{row.original.details}</span>),
            },
            {
                Header: 'Receiver Name',
                accessor: 'receiver_name',
                sortable: false,
                Cell: (row) => (<span>{row.original.receiver_name}</span>),
            },
            {
                Header: 'Receiver Account',
                accessor: 'receiver_account',
                sortable: false,
                Cell: (row) => (<span>{row.original.receiver_account}</span>),
            },
            {
                Header: 'Transaction',
                accessor: 'transaction',
                sortable: false,
                Cell: (row) => row.original.status === 'allocated'
                    ? <Link to={{ pathname: `/transactions/${row.original.transaction_id}` }}>
                        {row.original.transaction_number}
                    </Link>
                    : '',
            },
            {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                width: 150,
                Cell: (row) => (
                    <Button.List>
                        {row.original.type === BANK_ACCOUNT_TRANSACTION_TYPE_CREDIT
                            && (
                                <>
                                    {row.original.status === 'unrelated' && (
                                        <Button
                                            size='sm'
                                            color='success'
                                            onClick={setCreateTransactionModalVisible(true, row.original)}
                                        >
                                            Credit
                                        </Button>
                                    )}
                                    {showRefundButton(row.original) && (
                                        <Button
                                            size='sm'
                                            color='danger'
                                            onClick={setRefundTransactionModalVisible(true, row.original)}
                                        >
                                            Refund
                                        </Button>
                                    )}
                                </>
                            )}
                    </Button.List>
                ),
            },
        ],
        []
    );

    const showRefundButton = (item) => item.status === 'unrelated' || (item.status === 'allocated' && item.transaction_status === 'failed');

    const renderStatementTransactionStatus = (item) => {
        if (item.original.refund_reason !== null) {
            return (
                <div data-tip={item.original.refund_reason}>
                    {statementTransactionRelation(item.original.status)}
                    <span className='icon-fail-reason' />

                    <ReactTooltip className='react-tooltip' />
                </div>
            );
        }

        return statementTransactionRelation(item.original.status);
    };

    const filterFields = useMemo(
        () => [
            {
                title: 'Transaction type',
                name: 'type',
                type: 'select',
                options: objectToOptions({ debit: 'Debit', credit: 'Credit' }),
            },
            {
                title: 'Status',
                name: 'status',
                type: 'select',
                options: objectToOptions({ allocated: 'Allocated', unrelated: 'Unrelated', refunded: 'Refunded' }),
            },
            {
                title: 'Statement Date',
                name: 'date',
                type: 'text',
                placeholder: FILTER_MASKS.date.placeholder,
                mask: FILTER_MASKS.date.mask,
                value: new Date()
                    .toJSON()
                    .slice(0, 10),
            },
        ],
        [],
    );

    const setCreateTransactionModalVisible = useCallback(
        (value, item) => () => {
            if (!value) {
                setActiveBankAccountTransaction(null);
            } else {
                setActiveBankAccountTransaction(item);
            }

            setCreateTransactionModalOpen(value);
        },
        [],
    );

    const setUploadModalVisible = useCallback(
        (value) => () => {
            setUploadModalOpen(value);
        },
        [],
    );

    const setRefundTransactionModalVisible = useCallback(
        (value, item) => () => {
            if (!value) {
                setActiveBankAccountTransaction(null);
            } else {
                setActiveBankAccountTransaction(item);
            }

            setRefundTransactionModalOpen(value);
        },
        [],
    );

    const syncStatement = async (callback) => {
        setIsLoading(true);

        try {
            await api().put(`/admin-bank-account/${id}/import-bank-statement`);

            callback();
        } catch (exception) {
            toast.error(`Unable to sync statement: ${exception.data.message}`);
        }

        setIsLoading(false);
    };

    const uploadStatement = async () => {
        setUploadModalOpen(true);
    };

    return (
        <SiteWrapper title='Bank Account Statement'>
            <CreateTransactionModal
                modalIsOpen={isCreateTransactionModalOpen}
                onModalClose={setCreateTransactionModalVisible(false, null)}
                bankAccountTransaction={activeBankAccountTransaction}
                updateTransaction={updateItem}
            />
            <RefundTransactionModal
                activeTransaction={activeBankAccountTransaction}
                modalIsOpen={isRefundTransactionModalOpen}
                onModalClose={setRefundTransactionModalVisible(false)}
                updateTransaction={updateItem}
            />
            <UploadStatementModal
                modalIsOpen={isUploadModalOpen}
                onModalClose={setUploadModalVisible(false)}
                bankAccountId={parseInt(id, 10)}
            />
            <PaginatedFilteredList
                uri={bankAccountsURI}
                filterFields={filterFields}
                columns={columns}
                updateItems={updateItems}
                items={items}
                isLoading={isLoading}
                showSyncButton={bank_key === 'manobank'}
                sync={syncStatement}
                showUploadButton={bank_key === 'industra'}
                upload={uploadStatement}
            />
        </SiteWrapper>
    );
};

export default withRouter(AccountStatement);
