import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { navigate } from '../actions/navigate';
import { logoutUser } from '../actions/user/logout-user';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Nav, Site, Page, Icon } from 'tabler-react';
import ReactTooltip from 'react-tooltip';
import './SiteWrapper.scss';

import logoImage from '../images/logo.svg';
import { resolvePermissions } from '../services/permissions';

const navBarItems = [
    {
        value: 'Users',
        icon: 'users',
        to: '/users',
        permission: 'wallets',
        LinkComponent: NavLink,
    },
    {
        value: 'Transactions',
        icon: 'list',
        to: '/transactions',
        permission: 'transactions',
        LinkComponent: NavLink,
    },
    {
        value: 'Bank',
        icon: 'list',
        permission: 'transactions',
        subItems: [
            {
                value: 'Accounts',
                to: '/bank/bank-accounts',
                LinkComponent: NavLink,
            },
        ],
    },
    // {
    //     value: 'Centrolink',
    //     icon: 'list',
    //     permission: 'transactions',
    //     subItems: [
    //         {
    //             value: 'Transaction files',
    //             to: '/centrolink/transaction-files',
    //             LinkComponent: NavLink,
    //         },
    //         {
    //             value: 'Participants',
    //             to: '/centrolink/participants',
    //             LinkComponent: NavLink,
    //         },
    //     ],
    // },
    {
        value: 'Fees',
        icon: 'list',
        permission: 'fees',
        subItems: [
            {
                value: 'Monthly fees',
                to: '/fee/monthly-fees',
                LinkComponent: NavLink,
            },
            {
                value: 'Transaction fees',
                to: '/fee/transaction-fees',
                LinkComponent: NavLink,
            },
            {
                value: 'FX markup fees',
                to: '/fee/fx-markup-fees',
                LinkComponent: NavLink,
            },
            {
                value: 'Pricing groups',
                to: '/fee/pricing-groups',
                LinkComponent: NavLink,
            },
        ],
    },
    {
        value: 'Company Types',
        icon: 'book',
        to: '/company-types',
        permission: 'company_types',
        LinkComponent: NavLink,
    },
    {
        value: 'Reporting',
        icon: 'bar-chart',
        permission: 'reporting',
        subItems: [
            {
                value: 'Bank of Lithuania',
                icon: 'bar-chart',
                to: '/reporting/bank-of-lithuania',
                LinkComponent: NavLink,
            },
        ],
    },
    {
        value: 'Compliance',
        icon: 'feather',
        permission: 'compliance',
        subItems: [
            {
                value: 'Customers list',
                to: '/compliance/users',
                LinkComponent: NavLink,
            },
            {
                value: 'Actions List',
                to: '/compliance/actions-list/pending-verification',
                LinkComponent: NavLink,
            },
            {
                value: 'Document types',
                to: '/compliance/document-types',
                LinkComponent: NavLink,
            },
            // {
            //     value: 'Ondato Test',
            //     to: '/compliance/ondato-test',
            //     LinkComponent: NavLink,
            // },
            {
                value: 'Terms and Conditions',
                to: '/compliance/terms-conditions',
                LinkComponent: NavLink,
            },
        ],
    },
    {
        value: 'Bookkeeping',
        icon: 'book-open',
        permission: 'bookkeeping',
        subItems: [
            {
                value: 'Booking Entries',
                to: '/bookkeeping/booking-entries',
                LinkComponent: NavLink,
            },
        ],
    },
    {
        value: 'Payments',
        icon: 'book-open',
        permission: 'payments',
        subItems: [
            {
                value: 'Payment List',
                to: '/payment/payments',
                LinkComponent: NavLink,
            },
            {
                value: 'Settlement Reports',
                to: '/payment/settlement-reports',
                LinkComponent: NavLink,
            },
        ],
    },
    {
        value: 'Configuration',
        icon: 'list',
        permission: 'configurations',
        subItems: [
            {
                value: 'Fee wallets',
                to: '/configuration/fee-wallets',
                LinkComponent: NavLink,
            },
            {
                value: 'SatchelPay',
                to: '/configuration/satchelpay',
                LinkComponent: NavLink,
            },
            {
                value: 'Roles and permissions',
                to: '/configuration/roles-and-permissions',
                LinkComponent: NavLink,
            },
            {
                value: 'Admin users',
                to: '/configuration/admin-users',
                LinkComponent: NavLink,
            },
            {
                value: 'Bank holidays',
                to: '/configuration/bank-holidays',
                LinkComponent: NavLink,
            },
            {
                value: 'Default wallet limits',
                to: '/configuration/default-wallet-limits',
                LinkComponent: NavLink,
            },
            {
                value: 'Industra Statement',
                to: '/configuration/industra-statement',
                LinkComponent: NavLink,
            },
        ],
    },
];

const SiteWrapper = ({ logoutUser, adminInformation, children, title, titleDescription, options, subTitle, isMainWrapper, tooltipId }) => {
    const handleUserLogout = useCallback(() => logoutUser(), [logoutUser]);

    const renderTitle = () => (
        <div className='titleWrapper'>
            {title}
            {titleDescription && (
                <div data-for={tooltipId} data-tip={titleDescription}>
                    <Icon name='help-circle' />
                    <ReactTooltip
                        id={tooltipId}
                        multiline
                        className='tooltip-site-title'
                        place='right'
                    />
                </div>
            )}
        </div>
    );

    const renderPageContent = () => (
        <Page.Content title={renderTitle()} options={options} subTitle={subTitle} className={!isMainWrapper && 'container-wrapper'}>
            {children}
        </Page.Content>
    );

    return isMainWrapper ? (
        <Site.Wrapper
            footerProps={{}}
            headerProps={{
                alt: 'Admin',
                imageURL: logoImage,
                navItems: (
                    <Nav.Item type='div' className='d-none d-md-flex'>
                        <Button onClick={handleUserLogout} outline size='sm' color='secondary' icon='log-out'>
                            Logout
                        </Button>
                    </Nav.Item>
                ),
            }}
            navProps={{ itemsObjects: resolvePermissions(navBarItems, adminInformation.permissions) }}
        >
            {renderPageContent()}
        </Site.Wrapper>
    ) : renderPageContent();
};

SiteWrapper.defaultProps = {
    isMainWrapper: true,
};

SiteWrapper.propTypes = {
    children: PropTypes.node,
    adminInformation: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
    options: PropTypes.node,
    titleDescription: PropTypes.string,
    isMainWrapper: PropTypes.bool.isRequired,
    tooltipId: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            navigate,
            logoutUser,
        },
        dispatch,
    );

const mapStateToProps = (state) => ({
    adminInformation: state.admin.information,
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteWrapper);
