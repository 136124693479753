import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'tabler-react';
import { createApiClient as api } from 'src/services/api-client';
import { download } from 'src/services/download';
import { toast } from 'react-toastify';

class WalletClosingStatementActions extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isDownloading: false,
            isRemoving: false,
        };
    }

    downloadClosingReport = async () => {
        const { id, wallet_number } = this.props;
        const uri = `/admin-wallet-closing-statement/closing-statements/${id}/download`;
        const filename = `Closing-Statement-${wallet_number}.pdf`;

        this.setState({
            isDownloading: true,
        });

        try {
            const { data } = await api().get(uri, {
                responseType: 'blob',
            });

            download(filename, data);
        } catch ({ data }) {
            toast.error(`Failed to download statement: ${data.message}`);
        } finally {
            this.setState({
                isDownloading: false,
            });
        }
    }

    removeClosingReport = async () => {
        if (!confirm('Deletion can not be undone. Would you like to proceed?')) {
            return;
        }

        const { id, update_list } = this.props;
        const uri = `/admin-wallet-closing-statement/closing-statements/${id}`;

        this.setState({
            isRemoving: true,
        });

        try {
            await api().delete(uri);

            update_list();
        } catch (response) {
            if (response.data) {
                toast.error(`Failed to remove statement: ${response.data.message}`);
            }
        } finally {
            this.setState({
                isRemoving: false,
            });
        }
    }

    render () {
        return (
            <Button.List>
                <Button
                    disabled={this.state.isDownloading}
                    size='sm'
                    color='secondary'
                    icon='download'
                    onClick={this.downloadClosingReport}
                />
                <Button
                    disabled={this.state.isRemoving}
                    size='sm'
                    color='danger'
                    icon='trash'
                    onClick={this.removeClosingReport}
                />
            </Button.List>
        );
    }
}

WalletClosingStatementActions.propTypes = {
    id: PropTypes.number.isRequired,
    created_at: PropTypes.number.isRequired,
    wallet_number: PropTypes.string.isRequired,
    update_list: PropTypes.func.isRequired,
};

export default WalletClosingStatementActions;
