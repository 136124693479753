import Modal from 'react-modal';
import { Icon } from 'tabler-react';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Upload from 'src/components/Common/Upload/Upload/Upload';

const UploadStatementModal = ({ modalIsOpen, onModalClose, bankAccountId }) => {
    const handleModalClose = useCallback(() => {
        onModalClose();
    }, [onModalClose]);

    return (
        <Modal isOpen={modalIsOpen} className='react-modal'>
            <Icon name='x-circle' className='w-4 react-modal__closeButton' onClick={handleModalClose} />
            <Upload
                url={`/admin-bank-account/${bankAccountId}/import-bank-statement`}
                fileParam='file'
                params={{}}
                onClose={onModalClose}
                // onComplete={() => {
                // }}
            />
        </Modal>
    );
};

UploadStatementModal.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    bankAccountId: PropTypes.number.isRequired,
};

export default UploadStatementModal;
